import { CANCEL_PAYMENT_FUND_ORDER_API } from 'app/apiCalls/fundOrder/cancel'
import { CONFIRM_PAYMENT_FUND_ORDER_API } from 'app/apiCalls/fundOrder/confirm'
import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'

function* onFundOrderCancelSuccess(action) {
  if (flow(get('payload.name'), eq(CANCEL_PAYMENT_FUND_ORDER_API))(action)) {
    try {
      // TODO
    } catch (error) {
      console.log('onFundOrderCancelSuccess', error)
    }
  }
}

function* onFundOrderConfirmSuccess(action) {
  if (flow(get('payload.name'), eq(CONFIRM_PAYMENT_FUND_ORDER_API))(action)) {
    try {
      // TODO
    } catch (error) {
      console.log('onFundOrderConfirmSuccess', error)
    }
  }
}

function* watchOnFundOrderSuccess() {
  yield takeEvery(ACTIONS.COMPLETE, onFundOrderCancelSuccess)
  yield takeEvery(ACTIONS.COMPLETE, onFundOrderConfirmSuccess)
}

export function* fundOrderSaga() {
  yield watchOnFundOrderSuccess()
}
