import { Outlet, Route, Routes } from 'react-router-dom'

import { Layout } from 'antd'
import AppHeader from 'app/components/AppHeader'
import { HomePage } from 'app/components/HomePage'
import AccountIDPage from 'app/components/account/AccounIDPage'
import AccountBankPage from 'app/components/account/AccountBankPage'
import AccountInfoPage from 'app/components/account/AccountInfoPage'
import SettingPage from 'app/components/account/AccountSettingPage'
import ForgetPasswordOTPPage from 'app/components/account/ForgetPasswordOTPPage'
import ForgetPasswordPage from 'app/components/account/ForgetPasswordPage'
import FundAccountDetailPage from 'app/components/account/FundAccountDetailPage'
import FundAccountsPage from 'app/components/account/FundAccountsPage'
import ResetPasswordPage from 'app/components/account/ResetPasswordPage'
import ResetPasswordSuccessPage from 'app/components/account/ResetPasswordSuccessPage'
import CreatePasswordPage from 'app/components/auth/CreatePassword'
import LoginPage from 'app/components/auth/LoginPage'
import VerifyEmailOTPPage from 'app/components/auth/VerifyEmailOTPPage'
import VerifyPhonePage from 'app/components/auth/VerifyPhonePage'
import EmployeeProgramConfirmationPage from 'app/components/employeeOnboarding/EmployeeProgramConfirmationPage'
import EmployeeProgramInvestmentPlanPage from 'app/components/employeeOnboarding/EmployeeProgramInvestmentPlanPage'
import { EmployeeProgramRiskAppetitePage } from 'app/components/employeeOnboarding/pages'
import EmployeeProgramDetailPage from 'app/components/employeeProgram/EmployeeProgramDetailPage'
import EmployeeProgramLayout from 'app/components/employeeProgram/EmployeeProgramLayout'
import EmployeeProgramListPage from 'app/components/employeeProgram/EmployeeProgramListPage'
import EmployeeProgramTransactionPage from 'app/components/employeeProgram/EmployeeProgramTransactionPage'
import ProgramOrderCancelPage from 'app/components/employeeProgramOrder/ProgramOrderCancelPage'
import ProgramOrderDetailPage from 'app/components/employeeProgramOrder/ProgramOrderDetailPage'
import ProgramOrderListPage from 'app/components/employeeProgramOrder/ProgramOrderListPage'
import EmployeeOpenFundAccountPage from 'app/components/fundAccount/OpenFundAccount'
import FundOrderDetailPage from 'app/components/fundOrder/FundOrderDetailPage'
import EmployeeLayout from 'app/components/layouts/EmployeeLayout'
import LayoutGuest from 'app/components/layouts/LayoutGuest'
import LayoutOnboarding from 'app/components/layouts/LayoutOnboarding'
import LayoutRegister from 'app/components/layouts/LayoutRegister'
import UserProgramLayout from 'app/components/layouts/UserProgramLayout'
import {
  RiskAppetiteOptionsPage,
  RiskAppetitePage
} from 'app/components/riskAppetite/pages'
import UserProgramDetailPage from 'app/components/userProgram/UserProgramDetailPage'
import UserProgramUpdatePage from 'app/components/userProgram/UserProgramUpdatePage'
import {
  UserProgramListPage,
  UserProgramTransactionPage
} from 'app/components/userProgram/pages'
import {
  OnboardingAnalyticPage,
  OnboardingGoalsPage,
  OnboardingInvestmentPlanPage,
  OnboardingRiskAppetitePage,
  OnboardingStepsDetailPage,
  OnboardingTargetPage
} from 'app/components/userProgramNew/pages'
import OrderBuyFundSelectorPage from 'app/components/userProgramOrder/OrderBuyFundSelectorPage'
import OrderBuyPage from 'app/components/userProgramOrder/OrderBuyPage'
import OrderSellPage from 'app/components/userProgramOrder/OrderSellPage'
import UserProgramOrderListPage from 'app/components/userProgramOrder/UserProgramOrderListPage'
import EmployeeCompletingIDInfosPage from 'app/components/verification/CompletingIDInfosPage'
import EmployeeConfirmIDPage from 'app/components/verification/ConfirmIDPage'
import EmployeeConfirmKYCPage from 'app/components/verification/ConfirmKYCPage'
import EmployeeEditKYCPage from 'app/components/verification/EditKYCPage'
import EmployeeRequestVerifyPhonePage from 'app/components/verification/RequestVerifyPhonePage'
import EmployeeUpdateBankAccountPage from 'app/components/verification/UpdateBankAccountPage'
import EmployeeVerifyIDPage from 'app/components/verification/VerifyIDPage'
import EmployeeVerifyPhonePage from 'app/components/verification/VerifyPhonePage'
import EmployeeVerifySelfiePage from 'app/components/verification/VerifySelfiePage'
import {
  Question1Page,
  Question2Page,
  Question3Page
} from 'app/components/welcome/pages'
import PageNotFound from 'common/ui/error/404'
import ServerErrorPage from 'common/ui/error/server'
import { GuestRoute, PrivateRoute } from './AuthRoute'

function EmployeeRoute() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <EmployeeLayout />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/goals" element={<UserProgramListPage />} />
        <Route path="/employees" element={<EmployeeProgramListPage />} />
        <Route path="/account" element={<AccountInfoPage />} />
        <Route path="/account/bank" element={<AccountBankPage />} />
        <Route path="/account/identification" element={<AccountIDPage />} />
        <Route path="/account/fund-accounts" element={<FundAccountsPage />} />
        <Route
          path="/account/fund-accounts/:id"
          element={<FundAccountDetailPage />}
        />
        <Route path="/settings" element={<SettingPage />} />
        <Route path="/risk-appetite" element={<RiskAppetitePage />} />
        <Route
          path="/risk-appetite/options"
          element={<RiskAppetiteOptionsPage />}
        />
        <Route path="/goals/:id/edit" element={<UserProgramUpdatePage />} />
        <Route path="/goals/:id/buy" element={<OrderBuyFundSelectorPage />} />
        <Route path="/goals/:id/buy/:productId" element={<OrderBuyPage />} a />
        <Route
          path="/goals/:id/portfolio/:portfolioItemId/sell"
          element={<OrderSellPage />}
        />
        <Route path="/orders/:id" element={<FundOrderDetailPage />} />
        <Route
          path="/goals/:id/orders"
          element={<UserProgramOrderListPage />}
        />

        <Route element={<UserProgramLayout />}>
          <Route
            path="/goals/:id/transactions"
            element={<UserProgramTransactionPage />}
          />
          <Route path="/goals/:id" element={<UserProgramDetailPage />} />
        </Route>

        <Route element={<LayoutOnboarding />}>
          <Route path="/goals/new" element={<OnboardingStepsDetailPage />} />
          <Route path="/goals/new/theme" element={<OnboardingGoalsPage />} />
          <Route path="/goals/new/program" element={<OnboardingTargetPage />} />
          <Route
            path="/goals/new/risk-appetite"
            element={<OnboardingRiskAppetitePage />}
          />
          <Route
            path="/goals/new/portfolio"
            element={<OnboardingInvestmentPlanPage />}
          />
          <Route
            path="/goals/new/calculator"
            element={<OnboardingAnalyticPage />}
          />
        </Route>

        <Route element={<EmployeeProgramLayout />}>
          <Route
            path="/employees/:id/programs/:programId"
            element={<EmployeeProgramDetailPage />}
          />
          <Route
            path="/employees/:id/programs/:programId/program-orders"
            element={<ProgramOrderListPage />}
          />
          <Route
            path="/employees/:id/programs/:programId/transactions"
            element={<EmployeeProgramTransactionPage />}
          />
        </Route>
        <Route
          path="/employees/:id/programs/:programId/program-orders/:programOrderId"
          element={<ProgramOrderDetailPage />}
        />
        <Route
          path="/employees/:id/programs/:programId/program-orders/:programOrderId/cancel"
          element={<ProgramOrderCancelPage />}
        />
      </Route>

      <Route
        element={
          <PrivateRoute>
            <Layout style={{ minHeight: '100vh' }}>
              <AppHeader />
              <Outlet />
            </Layout>
          </PrivateRoute>
        }
      >
        <Route
          path="/:id/employee-programs/:programId/confirm"
          element={<EmployeeProgramConfirmationPage />}
        />
        <Route
          path="/:id/employee-programs/:programId/risk-appetite"
          element={<EmployeeProgramRiskAppetitePage />}
        />
        <Route
          path="/:id/employee-programs/:programId/investment-plans"
          element={<EmployeeProgramInvestmentPlanPage />}
        />
        <Route
          path="/:id/employee-programs/:programId/fund-accounts"
          element={<EmployeeOpenFundAccountPage />}
        />

        <Route path="/welcome/questions/1" element={<Question1Page />} />
        <Route path="/welcome/questions/2" element={<Question2Page />} />
        <Route path="/welcome/questions/3" element={<Question3Page />} />
      </Route>

      <Route
        element={
          <PrivateRoute>
            <Layout style={{ minHeight: '100vh' }}>
              <Outlet />
            </Layout>
          </PrivateRoute>
        }
      >
        <Route path="/create-password" element={<CreatePasswordPage />} />
      </Route>

      <Route
        element={
          <PrivateRoute>
            <LayoutRegister />
          </PrivateRoute>
        }
      >
        <Route
          path="/verification/phone"
          element={<EmployeeRequestVerifyPhonePage />}
        />
        <Route
          path="/verification/phone/:phone/:expires"
          element={<EmployeeVerifyPhonePage />}
        />
        <Route path="/verification/idcard" element={<EmployeeVerifyIDPage />} />
        <Route
          path="/verification/selfie"
          element={<EmployeeVerifySelfiePage />}
        />
        <Route
          path="/verification/idcard/confirm"
          console
          element={<EmployeeConfirmIDPage />}
        />
        <Route
          path="/verification/personal-information"
          element={<EmployeeCompletingIDInfosPage />}
        />
        <Route
          path="/verification/bank-account"
          element={<EmployeeUpdateBankAccountPage />}
        />
        <Route
          path="/verification/confirm"
          element={<EmployeeConfirmKYCPage />}
        />
        <Route path="/verification/edit" element={<EmployeeEditKYCPage />} />
      </Route>

      <Route
        element={
          <GuestRoute>
            <LayoutGuest />
          </GuestRoute>
        }
      >
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/verification/phone/:phone"
          element={<VerifyPhonePage />}
        />
        <Route
          path="/verification/email/:email/:expires"
          element={<VerifyEmailOTPPage />}
        />
      </Route>

      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/forget-password/otp" element={<ForgetPasswordOTPPage />} />
      <Route
        path="/forget-password/reset-password"
        element={<ResetPasswordPage />}
      />
      <Route
        path="/forget-password/success"
        element={<ResetPasswordSuccessPage />}
      />

      <Route path="/error" element={<ServerErrorPage />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default EmployeeRoute
