export const employeeProgramStatus = {
  1001: 'Chờ xác nhận',
  1002: 'Đang tham gia',
  1003: 'Đang chuyển giao',
  1004: 'Đã chuyển giao',
  1005: 'Đang huỷ',
  1006: 'Đã hủy'
}
export const employeeProgramStatusColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'lime',
  1004: 'blue',
  1005: 'volcano',
  1006: ''
}

export const employeeProgramStatusPending = 1001
export const employeeProgramStatusInProgress = 1002
export const employeeProgramStatusVesting = 1003
export const employeeProgramStatusCompleted = 1004
export const employeeProgramStatusCanceling = 1005
export const employeeProgramStatusCanceled = 1006
