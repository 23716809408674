import cancelApiCall from './cancel'
import confirmApiCall from './confirm'
import detailApiCall from './detail'
import listApiCall from './list'
import vietQRApiCall from './vietqr'

const ApiCall = {
  vietqr: vietQRApiCall,
  confirm: confirmApiCall,
  cancel: cancelApiCall,
  detail: detailApiCall,
  list: listApiCall
}

export default ApiCall
