import {
  BankFilled,
  BarChartOutlined,
  DollarOutlined,
  FileAddFilled,
  FundViewOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import SpeedometterSVG from '../common/svg/SpeedometterSVG'

function getItem(label, key, url, icon, children, type) {
  return {
    key: url ? url : key,
    icon,
    children,
    label: url ? <NavLink to={url}>{label}</NavLink> : label,
    type
  }
}

export const sideMenuItems = {
  business: () => [
    getItem('Quản trị nhân sự', 'employee', '/employees', <TeamOutlined />),
    getItem(
      'Quản lý chương trình',
      'employee-programs',
      '/employee-programs',
      <DollarOutlined />
    ),
    getItem('Quản lý lệnh', 'fund-orders', '/fund-orders', <FileAddFilled />),
    getItem('Thông tin doanh nghiệp', 'company', '/company', <BankFilled />),
    getItem('Cài đặt', 'settings', `/settings`, <SettingOutlined />),
    getItem('Bảng dự phóng', 'simulation', `/simulation`, <BarChartOutlined />)
  ],

  employee: () => [
    getItem('Tổng quan', 'home', '/', <PieChartOutlined />),
    getItem('Kế hoạch tài chính', 'goals', '/goals', <FundViewOutlined />),
    getItem(
      'Chương trình FutureFlex',
      'employees',
      '/employees',
      <DollarOutlined />
    ),
    getItem('Thông tin cá nhân', 'account', `/account`, <UserOutlined />),
    getItem(
      'Khẩu vị đầu tư',
      'risk-appetite',
      '/risk-appetite',
      <SpeedometterSVG />
    ),
    getItem('Cài đặt', 'settings', `/settings`, <SettingOutlined />)
  ]
}
