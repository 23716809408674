import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_FUND_ORDER_LIST_API = 'EMPLOYEE/FETCH_FUND_ORDER_LIST_API'

const apiCall = enhancedMakeFetchAction(FETCH_FUND_ORDER_LIST_API, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v2/orders`,
    params
  }),
  method: 'GET'
}))

export default apiCall
