import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const CONFIRM_PAYMENT_FUND_ORDER_API =
  'EMPLOYEE/CONFIRM_PAYMENT_FUND_ORDER_API'

const apiCall = enhancedMakeFetchAction(
  CONFIRM_PAYMENT_FUND_ORDER_API,
  ({ id, ...payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/app/v2/orders/${id}/confirm-payment`,
    method: 'POST',
    body: payload
  })
)

export default apiCall
