import { useFetchApiList } from '@stagapps/redux-utils'
import { Button, Divider, Typography } from 'antd'
import apiCall from 'app/apiCalls/surveys'
import InvestmentDetailDonutChart from 'app/components/employeeProgram/InvestmentDetailDonutChart'
import MetaDecorator from 'common/layout/MetaDecorator'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { cloudflareCdn } from 'utils/image'
import { riskValues } from './riskAppetite/config'

const { Title } = Typography

export function HomePage() {
  return (
    <>
      <MetaDecorator title="Tổng quan" />
      <Home />
    </>
  )
}

const Home = () => {
  const src = cloudflareCdn(`/futureflex/images/home/1.jpg`, 291, 333)
  const src2 = cloudflareCdn(`/futureflex/images/home/2.jpg`, 189, 144)
  const { data, load, isLoading, error } = useFetchApiList(apiCall.list, {
    resourceName: 'data'
  })

  useEffect(() => {
    load({ survey_type: 1002 })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className="flex md:flex-row flex-col gap-4">
      <UICard className="flex-grow">
        <div className="flex md:flex-row flex-col gap-5 items-center justify-between">
          <div className="md:max-w-sm">
            <p className="text-xl font-semibold">
              Xác định được mục tiêu là khởi đầu của mọi thành tựu
            </p>
            <p>
              Bạn cần một kế hoạch cụ thể, thông minh để xây dựng sự an tâm tài
              chính.
            </p>
            <Link to="/goals/new">
              <Button type="primary">Lên kế hoạch ngay hôm nay</Button>
            </Link>
          </div>
          <div>
            <USerProgramSvgComponent />
          </div>
        </div>
      </UICard>

      <UICard
        title={'Khẩu vị đầu tư'}
        extraTitle={
          <UIInfoTooltip title="Trong đầu tư, nhà đầu tư luôn đánh đổi giữa rủi ro và lợi nhuận kỳ vọng - rủi ro cao đi kèm với lợi nhuận kỳ vọng vượt trội và ngược lại. Stag dựa trên khẩu vị rủi ro của bạn để gợi ý phân bổ danh mục phù hợp cho mỗi kế hoạch đầu tư." />
        }
      >
        <div>
          {!!error ? (
            <ErrorMessage errors={error} />
          ) : data[0]?.answer_codes ? (
            <RiskAppetite {...riskValues[data[0].answer_codes]} />
          ) : (
            <div className="flex flex-col items-center md:px-20">
              <div className="max-w-[140px] p-2">
                <RiskAppetiteComponent />
              </div>
              <p className="max-w-xs text-gray-500 text-center">
                Hỗ trợ đề xuất danh mục thông minh và hoàn toàn miễn phí khi sử
                dụng.
              </p>
              <Link to="/risk-appetite/options">
                <Button type="primary">Thực hiện ngay</Button>
              </Link>
            </div>
          )}
        </div>
      </UICard>
    </div>
  )
}

const RiskAppetite = ({ defaultInitialValues, riskAppetite }) => {
  const { isMobile } = useCheckMobileDevice()
  return (
    <div>
      <div className="text-gray-500">Khẩu vị đầu tư</div>
      <div className="flex gap-4 items-center">
        <Title level={3}>{riskAppetite}</Title>
        <Link to="/risk-appetite/options">
          <Button size="small" type="primary" ghost>
            Thực hiện lại
          </Button>
        </Link>
      </div>
      <Divider />
      <p className="font-semibold">Gợi ý phân bổ danh mục</p>

      <div className="">
        <InvestmentDetailDonutChart
          data={defaultInitialValues}
          appendPadding={20}
          innerRadius={0.54}
          label={false}
          style={isMobile ? { height: 369 } : { height: 199, width: 496 }}
          legend={{
            position: isMobile ? 'bottom' : 'right',
            showContent: true,
            itemValue: {
              formatter: (text, item) => {
                const items = defaultInitialValues.filter(
                  d => d.product_code === item.value
                )
                return ' ' + (items.length ? items[0]?.percentage + '%' : '-')
              },
              style: {
                fontSize: 12
              }
            },

            itemName: {
              style: {
                fontSize: 12
              }
            }
          }}
        />
      </div>
    </div>
  )
}

const USerProgramSvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 300 172"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#DCECFD"
        d="M27.766 168.63h255.096c-3.272-38.829 2.758-94.417-36.396-122.733-36.309-26.26-67.889-26.844-121.044 17.433-14.07 11.888-32.711 18.357-48.326 11.528-20.75-7.541-49.834 3.225-55.706 29.548-5.603 25.116 2.3 41.91 6.376 64.224Z"
      />
      <path
        fill="#fff"
        d="M192.575 71.032h-37.12c1.126-2.348 2.272-2.612 4.536-3.21.468-.314.605-.925.773-1.462 2.811-7.805 13.603-6.177 17.753-.267 1.643-.79 3.715-1.55 5.16-.436.873.672 1.31 1.925 2.361 2.253.747.233 1.54-.094 2.321-.153 1.497-.113 3.751 1.85 4.216 3.276v-.001ZM203.44 42.346h-20.075c.423-.856 1.302-1.377 2.248-1.498 1.045-4.337 6.904-4.104 9.48-1.201.922-1.37 2.686-.196 3.384.854 1.785-1.741 4.27-.246 4.964 1.845h-.001ZM145.925 83.24h-21.427c.513-.783 1.026-1.221 1.928-1.472 1.337-5.153 8.309-5.15 11.55-1.72 1.203-1.606 3.866-.77 3.939 1.232 1.414-.523 3.461.557 4.012 1.96h-.002Z"
      />
      <path
        fill="#324D90"
        d="M120.738 168.609c-10.588-.05-18.934 2.045-24.327-10.026 4.326-.101 8.133 2.741 11.567 5.449-4.021-3.637-5.068-9.691-4.746-15.193 4.465 3.906 8.73 8.33 11.026 13.88-.344-4.133-.672-8.407.632-12.331 4.453 4.294 6.858 12.025 5.847 18.221h.001Z"
      />
      <path
        fill="#61A7FB"
        d="m193.61 58.4-17.029 23.997-24.652 5.206v38.799l-39.664 42.205h83.294l17.698-84.043-19.647-26.165Z"
      />
      <path
        fill="#BEDBFA"
        d="M225.846 32.835 193.61 58.4l6.771 26.165-26.716 16.212-2.793 40.304-35.833 27.527h125.587l7.613-67.886-17.187-16.836-6.757-34.113-18.449-16.937Z"
      />
      <path
        fill="#61A7FB"
        d="m244.294 49.772-25.702 27.163 5.394 8.372v34.012l18.326 5.365.868-3.134-13.016-7.446 3.666-29.77-9.844-6.4 22.481-17.197-2.173-10.965ZM177.251 168.607l25.292-33.166 39.336-4.431-12.969 6.254 17.557 13.358-31.51-7.44-18.057 6.626-3.447 18.799h-16.202Z"
      />
      <path
        fill="#DCECFD"
        d="m200.381 84.564 7.486-3.755 3.993 10.386-14.094 28.527h3.874l17.822-29.474-7.602-18.109h-11.102L193.61 58.4l6.771 26.165ZM243.181 121.55l1.635-6.93 9.072 10.065-3.276 20.562-2.772-1.039 2.016-19.523-6.675-3.135ZM179.407 97.29l3.64 27.394-5.796 20.563-16.988 19.333 10.609-23.5 2.792-40.304 5.743-3.486Z"
      />
      <path
        fill="#61A7FB"
        d="m268.239 100.721 9.793 32.177-.934 35.709h-16.472l7.613-67.886Z"
      />
      <path
        fill="#324D90"
        d="M273.482 168.607s11.736-2.77 14.108-12.413c0 0-8.694 1.776-11.34 4.754 0 0 6.989-8.148 6.46-18.47 0 0-10.548 5.975-11.208 13.717 0 0-.319-10.557-8.467-18.995 0 0-2.213 13.156 1.083 21.711 0 0-6.592-6.111-13.582-7.469 0 0-.132 10.593 10.416 12.358 0 0-5.405-2.443-12.13-2.037 6.649 6.906 12.942 7.303 24.657 6.844h.003Z"
      />
      <path
        fill="#2A437D"
        d="m230.923 32.09 11.062 20.739h-5.53l-9.302-17.157 3.77-3.582ZM212.909 32.09 201.848 52.83h5.531l9.302-17.157-3.772-3.582Z"
      />
      <path
        fill="#F08F3C"
        d="M235.856 1.32c-21.299-4.334-44.887 44.611-19.023 49.6 21.297 4.332 44.885-44.613 19.023-49.6Z"
      />
      <path
        fill="#F9CE68"
        d="M213.174 50.621c-25.866-4.99-2.268-53.939 19.026-49.597 25.864 4.989 2.267 53.939-19.026 49.597Z"
      />
      <path
        fill="#fff"
        d="M215.265 45.17c-20.18-3.893-1.768-42.082 14.843-38.695 20.179 3.892 1.769 42.082-14.843 38.696Z"
      />
      <path
        fill="#F9CE68"
        d="M217.356 39.72c-14.494-2.797-1.271-30.226 10.662-27.793 14.493 2.795 1.271 30.226-10.662 27.793Z"
      />
      <path
        fill="#fff"
        d="M219.448 34.268c-8.809-1.7-.772-18.37 6.479-16.89 8.807 1.698.771 18.369-6.479 16.89Z"
      />
      <path
        fill="#F9CE68"
        d="M224.926 26.118c-.635 1.654-2.152 2.863-3.388 2.698-3.125-.6-.274-6.517 2.297-5.99 1.237.164 1.725 1.637 1.091 3.29v.002Z"
      />
      <path
        fill="#152E60"
        d="m61.014 163.435.458 1.604 4.945 2.118c.59.252.97.833.97 1.474h-10.21l.387-5.196h3.45ZM38.092 163.435l.457 1.604 4.945 2.118c.59.252.971.832.971 1.473h-10.21l.387-5.195h3.45Z"
      />
      <path
        fill="#61A7FB"
        d="m57.201 75.748 10.292 8.308 1.942-17.027 4.396-.437 1.288 21.626a5.831 5.831 0 0 1-8.196 5.671L53.31 88.103l3.892-12.353-.001-.002Z"
      />
      <mask
        id="b"
        width={23}
        height={29}
        x={53}
        y={66}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance'
        }}
      >
        <path
          fill="#fff"
          d="m57.201 75.748 10.292 8.308 1.942-17.027 4.396-.437 1.288 21.626a5.831 5.831 0 0 1-8.196 5.671L53.31 88.103l3.892-12.353-.001-.002Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#3C7ED7"
          d="M58.015 76.381s7.817 8.867 8.907 17.507l-9.857-2.799.95-14.708Z"
        />
      </g>
      <path
        fill="#324D90"
        d="m67.187 126.52-4.975-23.41H40.954l-6.7 61.247h4.861l12.791-45.324 5.062 10.981.139 34.522h5.107l5.078-34.779a9.2 9.2 0 0 0-.104-3.237h-.001Z"
      />
      <path
        fill="#2A437D"
        d="m51.906 119.033 1.883-7.324c8.71 21.153 7.421 16.055 3.258 38.188l-.079-19.883-5.062-10.981Z"
      />
      <path
        fill="#F4D2C6"
        d="m52.07 74.925.577-5.397c-5.207-6.084 2.932-14.116 7.975-7.567 2.848 4.1 1.997 10.958-3.461 10.25l.855 4.169-5.947-1.456v.001Z"
      />
      <mask
        id="c"
        width={13}
        height={18}
        x={50}
        y={59}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance'
        }}
      >
        <path
          fill="#fff"
          d="m52.07 74.925.577-5.397c-5.207-6.084 2.932-14.116 7.975-7.567 2.848 4.1 1.997 10.958-3.461 10.25l.855 4.169-5.947-1.456v.001Z"
        />
      </mask>
      <g fill="#EDA19D" mask="url(#c)">
        <path d="M57.162 72.211s-1.196-.513-1.454-1.207c0 0 3.384 1.423 5.46-.22 0 0-1.352 2.158-4.006 1.427ZM56.213 64.654s1.915-2.814 5.712-1.462c0 0-1.731-2.907-2.495-3.231-.764-.323-2.72 1.784-2.72 1.784l-.499 2.908.002.001Z" />
      </g>
      <path
        fill="#59445A"
        d="M56.505 64.495s1.238-4.015 3.103-3.694c-2.906-4.745-12.52.728-9.983 5.875 1.026 1.808 2.92 3.808 2.92 3.808s1.844-.294 1.87-.533c.024-.241.68-3.312.68-3.312l1.41-2.142v-.002Z"
      />
      <path
        fill="#F4D2C6"
        d="M57.008 65.925c-.463 1.574-2.924.809-2.414-.75.464-1.574 2.925-.809 2.414.75Z"
      />
      <path
        fill="#61A7FB"
        d="m63.324 102.911-5.21-27.5-6.091-3.482c-10.184 1.758-12.765 32.3-13.188 35.298 0 0 12.342 3.983 24.49-4.317v.001Z"
      />
      <path
        fill="#324D90"
        d="m58.114 75.41-.59.461 2.085 16.945 5.092 4.178-.342-6.575-6.244-15.008ZM94.591 55.612l-17.147 2.416 1.12 7.95 17.148-2.416-1.12-7.95Z"
      />
      <path
        fill="#2A437D"
        d="m77.632 59.122-10.35 1.458.81 5.752 10.35-1.459-.81-5.751Z"
      />
      <path
        fill="#152E60"
        d="m67.493 62.081-7.884 1.111.388 2.748 7.883-1.11-.387-2.749ZM95.588 54.382l-1.742.245 1.412 10.022L97 64.403l-1.412-10.021Z"
      />
      <path
        fill="#F08F3C"
        d="m95.592 99.632-14.778 2.404a5.028 5.028 0 0 0-3.725 2.787l-4.13 8.607L74.88 116l4.783-9.237a3.709 3.709 0 0 1 2.25-1.853l14.396-4.22a.704.704 0 0 0-.717-1.056v-.002ZM45.816 136.861l6.48-13.497a5.025 5.025 0 0 1 3.728-2.786l9.423-1.531 1.921 2.57-10.212 1.979a3.705 3.705 0 0 0-2.414 1.634l-8.115 12.619a.704.704 0 0 1-.811-.986v-.002Z"
      />
      <path
        fill="#2A437D"
        d="m74.906 116.042-1.96-2.621-7.51 5.617 1.96 2.621 7.51-5.617Z"
      />
      <path
        fill="#324D90"
        d="M96.31 100.689a5357.963 5357.963 0 0 1-49.682 37.159c15.842-12 33.695-25.351 49.681-37.159Z"
      />
      <path
        fill="#fff"
        d="m58.115 75.41-1.864 1.45s-5.6-2.383-4.228-4.93l6.092 3.482v-.001Z"
      />
      <path
        fill="#F4D2C6"
        d="M69.867 66.986c.096-.084-2.757-4.665-.316-2.973l1.677 1.88 2.822-.399-.624 1.137-3.56.355Z"
      />
      <path
        fill="#61A7FB"
        d="m53.31 79.642 1.09 17.352 15.891 17.391-3.822 2.923c-2.026-1.697-18.046-12.178-19.063-14.297-3.066-3.386-2.425-14.658-3.086-18.87l8.99-4.498v-.001Z"
      />
      <path
        fill="#3C7ED7"
        d="M52.7 107.497c-7.41-5.468-7.292-7.915-7.857-16.374-1.521 9.01-4.567 11.819 5.978 16.718l1.88-.344H52.7ZM61.113 104.289 54.4 96.994l-.435-6.938 2.646 6.938 5.701 6.58-1.2.715Z"
      />
      <path
        fill="#F4D2C6"
        d="M69.58 114.726s2.27.347 2.59.748c.204.255.519 1.795.718 2.875a1.422 1.422 0 0 1-.784 1.541c-4.314 2.27-3.188 1.172-5.303-2.836l2.778-2.327.002-.001Z"
      />
      <path
        fill="#61A7FB"
        d="m85.12 141.951-.335 1.176-2.296-2.283.334-1.176 2.298 2.283Z"
      />
      <path
        fill="#fff"
        d="m85.458 140.768-.336 1.176-2.296-2.284.335-1.176 2.297 2.284Z"
      />
      <path
        fill="#61A7FB"
        d="m85.793 139.595-.336 1.176-2.296-2.282.335-1.178 2.297 2.284Z"
      />
      <path
        fill="#fff"
        d="m86.124 138.434-.336 1.176-2.298-2.282.336-1.177 2.298 2.283Z"
      />
      <path
        fill="#61A7FB"
        d="m86.458 137.261-.336 1.176-2.297-2.282.336-1.177 2.297 2.283ZM89.541 137.786l.335-1.178-3.156.728-.335 1.178 3.156-.728Z"
      />
      <path
        fill="#fff"
        d="m89.205 138.968.334-1.176-3.155.728-.336 1.176 3.157-.728Z"
      />
      <path
        fill="#61A7FB"
        d="m88.87 140.141.334-1.176-3.156.728-.334 1.176 3.156-.728Z"
      />
      <path
        fill="#fff"
        d="m88.538 141.301.337-1.176-3.157.728-.336 1.176 3.156-.728Z"
      />
      <path
        fill="#61A7FB"
        d="m88.204 142.474.336-1.176-3.156.728-.336 1.176 3.156-.728Z"
      />
      <path
        fill="#152E60"
        d="m86.96 137.39-8.91 31.239-.793.005 8.971-31.454.731.21ZM81.218 128.892l.084-1.221c-3.993 1.758-2.22 1.871-5.93-.406l-.084 1.221-.082 1.201-.083 1.217-.084 1.221 2.487 1.603v.008l-2.488-1.604-.084 1.222 2.487 1.603-1.823 33.672h.743l1.84-33.62 2.684-1.251.084-1.221.082-1.217.083-1.204.082-1.216-2.683 1.251v-.008l2.683-1.251h.002Z"
      />
      <path
        fill="#61A7FB"
        d="m72.789 140.837.019 1.223-2.86-1.523-.018-1.223 2.859 1.523Z"
      />
      <path
        fill="#fff"
        d="m72.77 139.607.02 1.223-2.86-1.523-.018-1.223 2.859 1.523Z"
      />
      <path
        fill="#61A7FB"
        d="m72.753 138.387.018 1.223-2.859-1.523-.018-1.223 2.859 1.523Z"
      />
      <path
        fill="#fff"
        d="m72.733 137.18.019 1.224-2.86-1.523-.018-1.222 2.86 1.521Z"
      />
      <path
        fill="#61A7FB"
        d="m72.716 135.961.018 1.224-2.859-1.523-.019-1.223 2.86 1.522ZM75.82 135.572l-.02-1.223-2.812 1.609.019 1.222 2.812-1.608Z"
      />
      <path
        fill="#fff"
        d="m75.837 136.803-.019-1.223-2.812 1.609.019 1.222 2.812-1.608Z"
      />
      <path
        fill="#61A7FB"
        d="m75.855 138.022-.019-1.223-2.81 1.609.018 1.222 2.81-1.608Z"
      />
      <path
        fill="#fff"
        d="m75.873 139.228-.019-1.223-2.81 1.608.018 1.225 2.811-1.61Z"
      />
      <path
        fill="#61A7FB"
        d="m75.892 140.447-.02-1.223-2.81 1.609.019 1.224 2.81-1.61Z"
      />
      <path
        fill="#152E60"
        d="m72.471 135.954.497 32.703.76-.011-.496-32.703-.76.011ZM20.696 168.842c87.598-.328 177.319-.331 264.917 0-87.598.331-177.319.328-264.917 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M19 0h269v170H19z" />
      </clipPath>
    </defs>
  </svg>
)

const RiskAppetiteComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 140 140"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#D1E3FF" d="M81.09 57.82h53.825V7.409H81.091v50.41Z" />
      <path fill="#E3EEFF" d="M79.159 57.82h54.855V7.409H79.159v50.41Z" />
      <path fill="#D1E3FF" d="M81.09 61.412h53.825V57.82H81.091v3.592Z" />
      <path fill="#E3EEFF" d="M75.936 61.412h54.855V57.82H75.936v3.592Z" />
      <path fill="#F5FCFF" d="M131.306 55.115V10.116H81.864v44.999h49.442Z" />
      <path
        fill="#fff"
        d="m126.129 55.115-15.885-44.999h-7.498l15.884 44.999h7.499Z"
      />
      <path
        fill="#E2EEFF"
        d="M130.052 39.85a.194.194 0 0 1-.194-.194V11.877a.194.194 0 0 1 .387 0v27.78a.194.194 0 0 1-.193.193Z"
      />
      <path
        fill="#fff"
        d="m117.02 55.115-15.884-44.999h-2.923l15.884 44.999h2.923Z"
      />
      <path fill="#D1E3FF" d="M82.208 55.118V10.119h-.344v44.999h.344Z" />
      <path
        fill="#D1E3FF"
        d="M78.518 13.58h53.824l.249-2.41H78.767l-.25 2.41Z"
      />
      <path
        fill="#D1E3FF"
        d="M78.518 15.344h53.824l.249-2.41H78.767l-.25 2.41Z"
      />
      <path
        fill="#D1E3FF"
        d="M78.518 17.46h53.824l.249-2.41H78.767l-.25 2.41Z"
      />
      <path
        fill="#D1E3FF"
        d="M78.518 19.816h53.824l.249-2.411H78.767l-.25 2.41ZM78.518 22.638h53.824l.249-2.408H78.767l-.25 2.408ZM78.518 25.816h53.824l.249-2.411H78.767l-.25 2.41Z"
      />
      <path
        fill="#F0F6FF"
        d="M68.883 133.025c38.043 0 68.883-1.801 68.883-4.023 0-2.223-30.84-4.024-68.883-4.024C30.84 124.978 0 126.779 0 129.002c0 2.222 30.84 4.023 68.883 4.023Z"
      />
      <path
        fill="#B6D4FF"
        d="M31.259 87.273H14.196v41.661h17.063v-41.66ZM57.366 70.753H40.303v58.181h17.063v-58.18ZM83.473 53.013H66.41v75.924h17.063V53.012Z"
      />
      <path
        fill="#DCE9FF"
        d="M25.208 87.273H8.145v41.661h17.063v-41.66ZM51.313 70.753H34.249v58.181h17.064v-58.18ZM77.42 53.013H60.356v75.924H77.42V53.012Z"
      />
      <path
        fill="#002189"
        d="M129.797 69.488a34.85 34.85 0 0 0-24.623-10.204h-5.035v.373a34.83 34.83 0 0 0-12.37 4.292c-16.545 9.56-22.302 31.024-12.754 47.569 5.213 9.03 14.378 15.496 25.127 17.055v.367h5.034c19.228 0 34.827-15.596 34.827-34.826 0-9.232-3.679-18.1-10.203-24.624l-.003-.002Zm-24.623 50.36c-9.537 0-17.856-5.174-22.291-12.874a25.5 25.5 0 0 1-3.45-12.863c0-14.213 11.517-25.74 25.741-25.74s25.74 11.527 25.74 25.74-11.528 25.74-25.74 25.74v-.003Z"
      />
      <path
        fill="#2E68BC"
        d="M125.88 94.108h9.086c0 19.23-15.596 34.826-34.827 34.826-12.888 0-24.147-7.008-30.161-17.418l7.87-4.545c4.433 7.7 12.754 12.877 22.291 12.877 14.213 0 25.741-11.527 25.741-25.74Z"
      />
      <path
        fill="#76A7FF"
        d="M100.139 59.284c9.232 0 18.099 3.68 24.623 10.204a34.85 34.85 0 0 1 10.204 24.623h-9.086c0-14.213-11.528-25.74-25.741-25.74v-9.087Z"
      />
      <path
        fill="#99C7FF"
        d="M100.139 59.284v9.086c-14.224 0-25.74 11.528-25.74 25.74 0 4.69 1.251 9.087 3.45 12.864l-7.871 4.544c-9.548-16.545-3.791-38.007 12.754-47.569a34.862 34.862 0 0 1 17.407-4.665Z"
      />
      <path
        fill="#EFC7B7"
        d="m91.61 83.597-2.791 1.632-4.212-6.213 2.792-1.632 4.211 6.213Z"
      />
      <path
        fill="#0F264C"
        d="m87.264 83.465 3.064-1.951a.257.257 0 0 1 .336.047l1.895 2.201a.52.52 0 0 1-.129.764c-1.08.664-1.635.927-2.982 1.787-.828.529-2.489 1.708-3.631 2.436-1.12.714-1.927-.364-1.515-.77 1.842-1.823 2.28-2.99 2.615-4.055a.848.848 0 0 1 .35-.459h-.003Z"
      />
      <path
        fill="#407BFF"
        d="M85.862 84.832c-.028 0-.056 0-.081-.006a.405.405 0 0 1-.303-.23.223.223 0 0 1 0-.22c.213-.387 1.64-.524 1.803-.538.028 0 .045.01.053.03a.056.056 0 0 1-.01.062c-.36.344-1.009.899-1.465.899l.003.003Zm1.27-.868c-.498.056-1.419.21-1.559.464a.125.125 0 0 0 0 .118c.05.098.124.154.222.17.274.043.77-.234 1.335-.755l.003.003Z"
      />
      <path
        fill="#407BFF"
        d="M87.105 83.961c-.47 0-1.246-.168-1.369-.456-.03-.073-.036-.188.12-.311a.53.53 0 0 1 .412-.112c.515.067 1.042.753 1.064.781a.052.052 0 0 1 .006.053c-.009.017-.023.031-.042.034a1.266 1.266 0 0 1-.19.011Zm-.915-.778a.421.421 0 0 0-.266.092c-.124.098-.096.162-.087.182.095.224.88.42 1.347.392-.151-.18-.555-.616-.927-.664a.547.547 0 0 0-.065-.005l-.002.003Z"
      />
      <path
        fill="#EFC7B7"
        d="M99.3 35.305c-.054.28-.099.48-.15.712l-.167.658c-.115.436-.241.865-.37 1.29a46.41 46.41 0 0 1-1.907 4.937c-.179.4-.37.795-.562 1.19l-.143.297-.081.168c-.042.08-.082.162-.13.238a4.162 4.162 0 0 1-.62.803c-.457.457-.947.753-1.42.997a8.808 8.808 0 0 1-1.412.554c-.932.272-1.84.434-2.74.532-1.807.196-3.554.177-5.335 0l.028-1.671c.81-.09 1.655-.182 2.473-.32.82-.125 1.632-.277 2.419-.461.784-.19 1.554-.406 2.24-.695.683-.271 1.302-.65 1.576-.994a.916.916 0 0 0 .14-.224l.182-.465c.154-.389.3-.78.448-1.17.3-.778.569-1.565.843-2.35.272-.783.52-1.573.75-2.362.12-.392.236-.787.34-1.176l.15-.586.135-.54 3.312.633v.005Z"
      />
      <path
        fill="#9FC6FF"
        d="M97.04 31.15c-1.333.339-2.266 5.824-2.266 5.824l3.674 2.498s3.522-5.533 2.495-6.998c-1.067-1.525-1.96-1.817-3.903-1.324Z"
      />
      <path
        fill="#77B3FC"
        d="m96.92 33.144 2.852.66.275 2.79c-.745 1.537-1.599 2.878-1.599 2.878l-2.229-1.515c.095-1.282.339-3.948.7-4.813Z"
      />
      <path
        fill="#FF8B7B"
        d="m84.767 45.99-2.293-2.324-.303 3.273s1.72 1.165 2.91.538l-.314-1.487Z"
      />
      <path
        fill="#FF8B7B"
        d="m79.777 43.319-.103 2.755 2.497.865.303-3.273-2.697-.347Z"
      />
      <path
        fill="#EFC7B7"
        d="m79.078 84.479-2.3-2.277 4.531-6.05 2.299 2.273-4.53 6.054Z"
      />
      <path
        fill="#0F264C"
        d="m78.067 80.581 3.31 1.498c.12.053.178.182.142.308l-.81 2.789a.521.521 0 0 1-.708.31c-1.145-.542-1.671-.862-3.124-1.52-.896-.406-3.234-1.352-4.472-1.912-1.21-.546-.748-1.812-.185-1.688 2.534.548 4.198.476 5.27.173a.842.842 0 0 1 .574.042h.003Z"
      />
      <path
        fill="#EDA19D"
        d="m84.61 79.016 2.17 3.203 2.791-1.632-2.17-3.204-2.791 1.633ZM83.605 78.428l-2.299-2.276-2.332 3.122 2.298 2.276 2.333-3.122Z"
      />
      <path
        fill="#9FC6FF"
        d="M111.717 50.666H98.585C95.497 31.691 97.04 31.15 97.04 31.15s2.114-.434 4.326-.705c.38-.045.761-.09 1.136-.124 1.944-.176 3.755-.106 5.964 0 .376.017.754.045 1.123.079 2.391.21 4.452.658 4.452.658-2.553 10.97-2.548 14.263-2.327 19.61l.003-.002Z"
      />
      <path
        fill="#318FFF"
        d="m109.595 30.4-.143.333c-.076.185-1.904 4.483-5.449 7.353l-.868.7-.664-.9c-1.993-2.698-1.397-6.21-1.1-7.442.381-.044.762-.09 1.137-.123 1.943-.176 3.755-.106 5.964 0 .375.017.753.045 1.123.079Z"
      />
      <path
        fill="#0F264C"
        d="M101.864 13.966c-2.87-1.282-4.598 3.593-4.402 7.048.196 3.455.714 7.689-.806 6.978-1.52-.712-.437-1.787-.437-1.787s-2.142 1.689.285 2.615c2.428.927 6.723.84 9.235-.344 1.442-2.17.82-10.284.82-10.284l-4.698-4.226h.003Z"
      />
      <path
        fill="#EFC7B7"
        d="M107.878 23.607c-.375 1.918-.75 5.435.591 6.714 0 0-1.761 4.2-5.141 6.936-2.111-2.862-.823-6.936-.823-6.936 2.139-.51 2.083-2.097 1.711-3.59l3.66-3.124h.002Z"
      />
      <path
        fill="#EDA19D"
        d="m106.386 24.884-2.167 1.848c.089.353.159.711.185 1.058.817-.117 1.94-1.016 2.027-1.87.042-.426.025-.837-.045-1.036Z"
      />
      <path
        fill="#EFC7B7"
        d="M108.105 18.553c.37 3.186.596 4.53-.708 6.431-1.96 2.862-5.956 2.621-7.272-.392-1.184-2.713-1.59-7.47 1.271-9.276 2.82-1.78 6.339.048 6.709 3.237Z"
      />
      <path
        fill="#0F264C"
        d="M101.746 16.355c1.148 1.35 4.609 5.264 7.045 4.757 2.487-.518 2.571-5.15-.425-7.064-2.996-1.918-9.697-1.302-6.62 2.31v-.003Z"
      />
      <path
        fill="#0F264C"
        d="M108.937 10.735c-2.282-.756-2.473 1.473-2.308 2.862l2.806 2.064s1.47-.213 2.57-.843c1.101-.63-.786-3.33-3.068-4.085v.002Z"
      />
      <path
        fill="#EFC7B7"
        d="M109.452 21.291a2.435 2.435 0 0 1-.829 1.588c-.624.529-1.246.059-1.333-.703-.078-.686.152-1.79.905-2.027.739-.233 1.327.389 1.257 1.142Z"
      />
      <path
        fill="#14365B"
        d="M85.761 64.621c-.095 2.884.784 6.398 1.753 9.322.207.624.415 1.226.622 1.783.882 2.403 1.669 4.074 1.669 4.074l-3.862 2.184s-.526-.66-1.299-1.823a44.616 44.616 0 0 1-1.148-1.823c-2.167-3.623-5.003-9.545-5.138-15.847-.213-9.862 20.23-11.828 20.23-11.828h9.66l-2.708 7.692s-19.602 1.005-19.779 6.264v.002Z"
      />
      <path
        fill="#0E2942"
        d="M88.136 75.729c-.504.837-1.868 2.974-3.492 4.438a44.616 44.616 0 0 1-1.148-1.823l4.018-4.399c.207.625.415 1.227.622 1.784ZM91.462 56.445l-.412 4.192c-2.858.896-5.06 2.12-5.275 3.788-.484-3.71 2.103-5.902 5.687-7.98Z"
      />
      <path
        fill="#14365B"
        d="M102.054 50.666S89.953 55.602 88.11 61.06c-2.352 6.963-8.747 16.805-8.747 16.805l2.686 3.497s7.383-7.15 12.398-15.346c6.3-10.299 21.487.576 17.273-15.347h-9.666v-.003Z"
      />
      <path
        fill="#407BFF"
        d="M77.666 80.867h-.011c-.417-.081-1.143-.275-1.302-.641-.056-.13-.037-.264.05-.404a.239.239 0 0 1 .207-.123c.4 0 1.028.972 1.098 1.084a.056.056 0 0 1 0 .059.058.058 0 0 1-.045.022l.003.003Zm-1.053-1.059c-.047 0-.087.023-.117.073-.07.11-.084.207-.042.303.092.215.498.42 1.094.551-.224-.333-.677-.924-.935-.924v-.003Z"
      />
      <path
        fill="#407BFF"
        d="M77.666 80.867a.058.058 0 0 1-.044-.023c-.23-.316-.44-1.162-.241-1.439.047-.064.134-.131.3-.081.109.034.19.106.24.216.188.42-.188 1.26-.204 1.296-.009.017-.025.03-.045.03h-.006Zm-.092-1.453a.121.121 0 0 0-.104.053c-.148.207.006.899.185 1.229.093-.23.3-.812.165-1.112a.283.283 0 0 0-.249-.17h.003Z"
      />
      <path
        fill="#318FFF"
        d="m111.916 49.896.104 1.176c.044.092-.062.185-.213.185H98.521c-.118 0-.216-.059-.224-.132l-.118-1.176c-.008-.08.095-.15.224-.15h13.3c.098 0 .185.038.213.097Z"
      />
      <path
        fill="#14365B"
        d="M110.129 51.383h.356c.07 0 .126-.036.12-.081l-.168-1.526c-.005-.045-.067-.081-.137-.081h-.355c-.07 0-.126.036-.121.08l.168 1.527c.006.045.067.081.137.081ZM99.523 51.383h.356c.07 0 .125-.036.12-.081l-.168-1.526c-.006-.045-.067-.081-.137-.081h-.356c-.07 0-.126.036-.12.08l.168 1.527c.005.045.067.081.137.081ZM104.826 51.383h.356c.07 0 .126-.036.12-.081l-.168-1.526c-.006-.045-.067-.081-.137-.081h-.356c-.07 0-.126.036-.12.08l.168 1.527c.005.045.067.081.137.081Z"
      />
      <path
        fill="#77B3FC"
        d="m110.956 36.148 1.694 1.442a65.159 65.159 0 0 0-.762 4.94c-.316-1.426-1.123-5.29-.935-6.382h.003Z"
      />
      <path
        fill="#fff"
        d="M75.057 41.589h23.047l3.34 13.61H78.397l-3.34-13.61Z"
      />
      <path
        fill="#fff"
        d="m86.733 52.666 14.095 3.287 9.047-2.537-.095-.75H86.733Z"
      />
      <path
        fill="#318FFF"
        d="M74.346 41.589h23.046l3.341 13.61H77.686l-3.34-13.61ZM77.686 55.2l.092.753h23.05l-.092-.754h-23.05Z"
      />
      <path
        fill="#EFC7B7"
        d="M115.284 34.667a58.194 58.194 0 0 1 1.658 5.816c.232.988.431 1.988.58 3.024.075.518.134 1.047.165 1.601.014.28.022.563.014.863l-.009.23-.005.125-.017.171a3.69 3.69 0 0 1-.14.652c-.274.824-.75 1.375-1.201 1.812-.462.44-.935.767-1.409 1.067-.952.58-1.915 1.005-2.884 1.369a34.51 34.51 0 0 1-2.923.924c-.988.25-1.957.473-2.976.636l-.401-1.622a55.388 55.388 0 0 0 2.691-1.05 32.607 32.607 0 0 0 2.601-1.184c.835-.428 1.644-.885 2.336-1.397.341-.252.658-.521.89-.787.235-.26.37-.52.389-.644.009.028-.005-.493-.059-.899a18.72 18.72 0 0 0-.221-1.33 50.55 50.55 0 0 0-.649-2.758 85.517 85.517 0 0 0-.79-2.777c-.263-.927-.563-1.862-.84-2.764l3.2-1.07v-.008Z"
      />
      <path
        fill="#9FC6FF"
        d="M114.047 31.058c1.316.4 3.329 5.563 3.329 5.563l-5.104 3.649s-2.296-4.416-1.742-6.115c.577-1.773 2.016-3.554 3.517-3.097Z"
      />
      <path
        fill="#EFC7B7"
        d="m106.398 51.444-3.066-.775 1.461 2.946s2.075.09 2.758-1.07l-1.153-1.1ZM100.854 51.786l1.355 2.4 2.581-.574-1.461-2.946-2.475 1.12Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h140v140H0z" />
      </clipPath>
    </defs>
  </svg>
)
