import { Card, Space, Typography } from 'antd'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'

const { Title } = Typography

function UICard({ title, children, extra, extraTitle, ...props }) {
  const { isMobile } = useCheckMobileDevice()
  return (
    <Card size={isMobile ? 'small' : 'default'} {...props}>
      {(title && extraTitle) || extra ? (
        <div className="flex justify-between mb-5">
          {!title && !extraTitle ? (
            <div></div>
          ) : (
            <Space>
              {title ? <Title level={4}>{title}</Title> : null}
              {extraTitle}
            </Space>
          )}
          {extra}
        </div>
      ) : null}
      <div className="">{children}</div>
    </Card>
  )
}

export default UICard
