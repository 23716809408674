import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

export const GET_USER_PROFILE = 'EMPLOYEE/GET_USER_PROFILE'

const apiCall = enhancedMakeFetchAction(GET_USER_PROFILE, () => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v1/users/profile`
  }),

  method: 'GET'
}))

export default apiCall
