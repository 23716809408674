import { cloudflareCdn } from 'utils/image'

const AuthLayout = ({ imgUrlPath, children }) => {
  const backgroundImageUrl = cloudflareCdn(imgUrlPath, 1000, 667)

  const renderForm = () => {
    return (
      <div className="flex flex-col md:gap-6 gap-2 p-5">
        {children}
        <div className="bg-[#EBF3FF] p-5 rounded-md flex flex-row">
          <img src={cloudflareCdn('/stagvn/assets/svg/app_qr.svg')} />
          <div className="p-5">
            Chưa có tài khoản? Tải ứng dụng Stag Invest và mở khóa tính năng hấp
            dẫn
            <div className="flex sm:flex-row flex-col gap-3 my-3">
              <a
                href="https://apps.apple.com/vn/app/stag-invest-t%E1%BB%B1-tin-t%C3%A0i-ch%C3%ADnh/id1612795130"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <img
                  src={cloudflareCdn('/stagvn/assets/svg/app_appstore.svg')}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=vn.stag"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <img
                  src={cloudflareCdn('/stagvn/assets/svg/app_playstore.svg')}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="md:flex relative overflow-visible">
      <div className="md:h-screen md:w-[1/2] bg-[#0A1A41]">
        <div
          style={{
            backgroundImage: `url(${backgroundImageUrl})`
          }}
          className={`bg-center	bg-no-repeat bg-cover w-full flex-shrink md:h-full md:max-h-[51vh]`}
        >
          <div
            className={
              'md:hidden w-full justify-center items-center bg-white bg-opacity-70 backdrop-blur'
            }
          >
            {renderForm()}
          </div>
        </div>

        <div className="md:px-12 p-2 flex flex-col text-white h-fit flex-grow-0 ">
          <p className="text-4xl font-semibold">
            Phúc lợi song hành - Tương Lai Tỏa Sáng
          </p>
          <p>
            Giúp bạn xây dựng kế hoạch tài chính, tạo nền tảng vững chắc để đạt
            được mục tiêu và hưởng một cuộc sống an yên và phồn thịnh.
          </p>
        </div>
      </div>

      <div className="w-[1/2] h-screen justify-center items-center bg-transparent bg-white md:flex hidden">
        {renderForm()}
      </div>
    </div>
  )
}
export default AuthLayout
