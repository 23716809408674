import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_FUND_ORDER_DETAIL_API = 'EMPLOYEE/FETCH_FUND_ORDER_DETAIL_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_FUND_ORDER_DETAIL_API,
  ({ id, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/orders/${id}`,
      params
    }),
    method: 'GET'
  })
)

export default apiCall
