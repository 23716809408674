import { DeleteOutlined } from '@ant-design/icons'
import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Title } from '@tremor/react'
import { Alert, Button, Descriptions, Space, Tag } from 'antd'
import apiCall from 'app/apiCalls/fundOrder'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import CopyToClipboard from 'common/ui/CopyToClipboard'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { orderStatusColors, orderTypeLabels } from 'data/fundOrder'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { formatCurrency } from 'utils/formatAmount'
import { formatDate } from 'utils/formatDate'

const { Item } = Descriptions

function FundOrderDetailPage() {
  const params = useParams()

  const { id } = params
  const { isMobile } = useCheckMobileDevice()
  const {
    data: vietQRData,
    load: loadQRCode,
    isLoading: isLoadingQRCode
  } = useFetchApiGet(apiCall.vietqr, {
    resourceName: 'data'
  })
  const {
    data: fundOrder,
    load: loadFundOrder,
    isLoading: isFundOrderLoading,
    error: fundOrderError
  } = useFetchApiGet(apiCall.detail, {
    resourceName: 'data'
  })

  const {
    post: confirmPayment,
    isLoading: isConfirmLoading,
    error: confirmError
  } = usePostApi(apiCall.confirm, {
    resourceName: 'data'
  })
  const {
    post: cancelOrder,
    isLoading: isCancelLoading,
    error: cancelError
  } = usePostApi(apiCall.cancel, {
    resourceName: 'data'
  })

  useEffect(() => {
    loadFundOrder({ id })
    loadQRCode({ fundOrderId: id })
  }, [])

  useEffect(() => {
    if (!isConfirmLoading && !confirmError) {
      loadFundOrder({ id })
    }
  }, [isConfirmLoading])

  useEffect(() => {
    if (!isCancelLoading && !cancelError) {
      loadFundOrder({ id })
    }
  }, [isCancelLoading])

  if (isFundOrderLoading) {
    return <LoadingSpinner />
  }

  if (!!fundOrderError) {
    return <ErrorMessage errors={fundOrderError} />
  }

  if (!fundOrder) {
    return <ErrorMessage errors={['Không tìm thấy lệnh']} />
  }

  const {
    code,
    created_at,
    order_side,
    fund_code,
    amount,
    status,
    status_label,
    fund_payment_bank_account_name,
    fund_payment_bank_account_number,
    fund_payment_bank_name,
    fund_payment_bank_branch_name,
    fund_payment_message,
    can_cancel
  } = fundOrder || {}

  return (
    <div>
      <MetaDecorator title={'Chương trình cá nhân - Chi tiết lệnh'} />
      <PageTitle
        title={'Chi tiết lệnh ' + orderTypeLabels[order_side]?.toLowerCase()}
      />
      <div className="flex md:flex-row flex-col gap-4 md:text-sm text-xs">
        <UICard title={'Thông tin lệnh'} className="h-fit">
          <Descriptions
            bordered
            column={1}
            size={isMobile ? 'small' : 'middle'}
          >
            <Item label="Mã lệnh">{code}</Item>
            <Item label="Ngày tạo">{formatDate(created_at)}</Item>
            <Item label="Loại lệnh">{orderTypeLabels[order_side]}</Item>
            <Item label="Sản phẩm">{fund_code}</Item>
            <Item label="Số tiền cần thanh toán">
              {<div className="font-semibold"> {formatCurrency(amount)}</div>}
            </Item>
          </Descriptions>
        </UICard>

        <UICard title={'Thông tin chuyển khoản'} className="flex-grow">
          <>
            {isLoadingQRCode ? (
              <LoadingSpinner />
            ) : vietQRData && vietQRData.qr_code ? (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Title level={5}>Thanh toán qua QR code</Title>
                <img src={vietQRData.qr_code} />
                <Alert
                  className="mb-4"
                  message=" Một số Ngân hàng chưa hỗ trợ quét mã QR. Quý khách vui lòng đối chiếu
                  lại thông tin trước khi chuyển khoản."
                  banner
                />
              </Space>
            ) : null}
          </>
          <Descriptions
            bordered
            column={1}
            size={isMobile ? 'small' : 'middle'}
          >
            <Item label="Tên ngân hàng">{fund_payment_bank_name}</Item>
            <Item label="Chi nhánh">{fund_payment_bank_branch_name}</Item>
            <Item label="Nội dung chuyển khoản">
              {
                <div className="flex md:flex-row flex-col gap-2 items-center">
                  <div>{fund_payment_message}</div>
                  <CopyTag text={fund_payment_message} />
                </div>
              }
            </Item>
            <Item label="Tên người thụ hưởng">
              <div className="flex md:flex-row flex-col gap-2 items-center">
                <div>{fund_payment_bank_account_name}</div>
                <CopyTag text={fund_payment_bank_account_name} />
              </div>
            </Item>
            <Item label="Số tài khoản thụ hưởng">
              {
                <div className="flex md:flex-row flex-col gap-2 items-center">
                  <div>{fund_payment_bank_account_number}</div>
                  <CopyTag text={fund_payment_bank_account_number} />
                </div>
              }
            </Item>

            <Item label="Trạng thái">
              <Tag color={orderStatusColors[status]}>{status_label}</Tag>
            </Item>
          </Descriptions>

          {order_side == 'buy' && (
            <div className="flex justify-between mt-4">
              <div className="">
                <Button
                  loading={isCancelLoading}
                  danger
                  onClick={() => {
                    if (confirm('Xác nhận hủy lệnh?')) {
                      cancelOrder({ id: order.id })
                    }
                  }}
                  disabled={!can_cancel}
                >
                  <DeleteOutlined />
                </Button>
              </div>
              <div className="">
                <Button
                  type="primary"
                  loading={isConfirmLoading}
                  onClick={() => {
                    if (confirm('Xác nhận đã thanh toán?')) {
                      confirmPayment({ id: fundOrder.id })
                    }
                  }}
                  disabled={status !== 1}
                >
                  Xác nhận đã thanh toán
                </Button>
              </div>
            </div>
          )}
        </UICard>
      </div>
    </div>
  )
}

export default FundOrderDetailPage

const CopyTag = ({ text }) =>
  text && (
    <CopyToClipboard text={text}>
      <Tag bordered={false} color="processing">
        Sao chép
      </Tag>
    </CopyToClipboard>
  )
